import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorSelect,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/impermeable-dividers"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
const { calculator, faq } = locale
const log = logger.extend("calculator:impermeable-dividers")

const SECTION_KEY = "impermeable_dividers"

const RESET_KEYS = [
  "impermeable_dividers_number_of_locations",
  "impermeable_dividers_dividers_per_location",
  "impermeable_dividers_width",
  "impermeable_dividers_height",
]

const CalculatorImpermeableDividers = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.impermeable_dividers}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.impermeable_dividers}</h2>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="impermeable_dividers_total" />
            <div className="text-sm">Dividers</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <p className="mb-5">My business has:</p>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="impermeable_dividers_number_of_locations"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="impermeable_dividers_number_of_locations"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="impermeable_dividers_number_of_locations">
                  locations that require dividers
                </label>
              </Fieldset>

              <p className="mb-5">My business needs:</p>

              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="impermeable_dividers_dividers_per_location"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="impermeable_dividers_dividers_per_location"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label
                  htmlFor="impermeable_dividers_dividers_per_location"
                  className="flex-grow"
                >
                  dividers per location
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <>
                    <div>
                      <ErrorMessage
                        errors={errors}
                        name="impermeable_dividers_width"
                      />
                    </div>
                    <div>
                      <ErrorMessage
                        errors={errors}
                        name="impermeable_dividers_height"
                      />
                    </div>
                  </>
                }
              >
                <div className="flex items-center">
                  <CalculatorInput
                    type="number"
                    name="impermeable_dividers_width"
                    className="w-16 mr-2"
                    register={register({
                      min: {
                        value: 0,
                        message: "Width must be 0 or greater",
                      },
                    })}
                  />
                  <span className="mr-2">×</span>
                  <CalculatorInput
                    type="number"
                    name="impermeable_dividers_height"
                    className="w-16 mr-5"
                    register={register({
                      min: {
                        value: 0,
                        message: "Height must be 0 or greater",
                      },
                    })}
                  />
                </div>

                <label htmlFor="impermeable_dividers_width">
                  divider size (in)
                </label>
              </Fieldset>
              <Fieldset>
                <CalculatorSelect
                  name="impermeable_dividers_preferred_material"
                  className="mr-5"
                  options={[
                    {
                      value: "plexiglass",
                      label: "Plexiglass",
                    },
                    {
                      value: "tempered_glass",
                      label: "Tempered Glass",
                    },
                    {
                      value: "polycarbonate",
                      label: "Polycarbonate",
                    },
                  ]}
                  register={register}
                />{" "}
                <label htmlFor="impermeable_dividers_preferred_material">
                  preferred divider material
                </label>
              </Fieldset>
              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>

            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorImpermeableDividers
