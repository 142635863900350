import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "What is a rope and stanchion divider?",
    body: (
      <>
        <p>
          This is often either a retractable belt or rope, between two poles,
          often used to divide queues and/or lines.
        </p>
      </>
    ),
  },
  {
    heading: "How should impermeable dividers be used?",
    body: (
      <>
        <p>
          As per CDC, OSHA, and the{" "}
          <OutboundLink href="https://acoem.org/COVID-19-Resource-Center/COVID-19-Q-A-Forum/Could-you-provide-guidance-on-the-use-of-plexiglass-barriers-for-workplaces-for-sneeze-guard%E2%80%9D-dropl">
            American College of Occupational and Environmental Medicine
          </OutboundLink>
          , impermeable dividers like plexiglass partitions should be used when
          possible to separate workers from each other and from members of the
          general public, such as at:
        </p>
        <ul>
          <li>at cash register lanes and customer service windows,</li>
          <li>in rideshare cars, or</li>
          <li>between workstations in a manufacturing facility.</li>
        </ul>
        <p>
          When possible, this approach should be coupled with other efforts to
          create distance, such as opening only every other cash register or
          moving workstations.
        </p>
        <p>
          When installing barriers, care should be taken to avoid interfering
          with ventilation requirements and HVAC systems. Barriers should be
          cleaned daily with cleaners that will not damage the barrier.
        </p>
      </>
    ),
  },
  {
    heading: "What should you look for when shopping for impermeable dividers?",
    body: (
      <>
        <p>
          While CDC and OSHA have not published guidance on this, the{" "}
          <OutboundLink href="https://ncceh.ca/content/blog/physical-barriers-covid-19-infection-prevention-and-control-commercial-settings">
            Canadian National Collaborating Centre for Environmental Health
            (NCCEH) notes
          </OutboundLink>{" "}
          that the dimensions of the barrier should take into account:
        </p>
        <ol>
          <li>
            the breathing zone areas of all users, which depends on their
            posture (sitting or standing).
          </li>
          <li>the height should be greater than the tallest standing user,</li>
          <li>
            the width should be as wide as possible (e.g., the width of the
            entire counter), and
          </li>
          <li>
            all openings should be as small as possible and offset from the
            breathing zone (e.g., to the side of the window rather than in the
            middle).
          </li>
        </ol>
      </>
    ),
  },
  {
    heading: "What are the different types of dividers?",
    body: (
      <>
        <ul>
          <li>
            Plexiglass: The{" "}
            <OutboundLink href="https://acoem.org/COVID-19-Resource-Center/COVID-19-Q-A-Forum/Could-you-provide-guidance-on-the-use-of-plexiglass-barriers-for-workplaces-for-sneeze-guard%E2%80%9D-dropl">
              American College of Occupational and Environmental Medicine notes
            </OutboundLink>{" "}
            that plexiglass is the most widely available material for these
            dividers, and is advantageous because it is easy to install,
            transparent, and easy to clean.
          </li>
          <li>
            Tempered Glass &amp; Polycarbonate Glass: Tempered glass and
            polycarbonate also share these qualities and are more durable – but
            are also more expensive.
          </li>
          <li>
            Vinyl Strip Curtains: Vinyl strip curtains may also be used, but are
            less desirable because they have more openings and are more
            difficult to clean.
          </li>
        </ul>
      </>
    ),
  },
]

const ImpermeableDividersContent = () => <Content sections={sections} />

export default ImpermeableDividersContent
